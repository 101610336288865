import React from 'react';
//MaterialUI
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import {Button, createTheme} from "@mui/material";
import {ThemeProvider} from "@mui/material/styles";
import logo from './images/codebookmark-logo.png';
import Features from "../intro/Features/Features";
import KeyVisual from "./KeyVisual/KeyVisual";


let theme = createTheme();
theme = createTheme(theme, {
  nav: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});
export default function Intro(props) {
  return (
    <ThemeProvider theme={theme}>
      <Container component="main">
        <CssBaseline/>
        <div>
          <section>
            <nav style={theme.nav}>
              <img alt="bookmark logo" id="logo" src={logo} style={{marginTop: '2rem'}}/>
              <Button variant="outlined" color="error" href={"/home"}>Login</Button>
            </nav>
          </section>
          <KeyVisual/>
          <Features/>
        </div>
      </Container>
    </ThemeProvider>
  );
}