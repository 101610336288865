import IllusFeatureTab1 from '../images/illustration-features-tab-1.svg';
import IllusFeatureTab2 from '../images/illustration-features-tab-2.svg';
import IllusFeatureTab3 from '../images/illustration-features-tab-3.svg';

export const tabsInfoData = [
  {
    id: 0,
    title: 'Bookmark in one click',
    text: `Organize your bookmarks however you like. Our simple drag-and-drop interface 
        gives you complete control over how you manage your favourite sites.`,
    img: IllusFeatureTab1,
    imgSize: '89.4%'
  },
  {
    id: 1,
    title: 'Intelligent search',
    text: `Our powerful search feature will help you find saved sites in no time at all. 
        No need to trawl through all of your bookmarks.`,
    img: IllusFeatureTab2,
    imgSize: '77%'
  },
  {
    id: 2,
    title: 'Share your bookmarks',
    text: `Easily share your bookmarks and collections with others. Create a shareable 
        link that you can send at the click of a button.`,
    img: IllusFeatureTab3,
    imgSize: '77%'
  }
]

export const tabsData = [
  {
    index: 0,
    idName: 'bookmarking',
    isChecked: true,
    labelText: 'Simple Bookmarking'
  },
  {
    index: 1,
    idName: 'searching',
    isChecked: false,
    labelText: 'Speedy Searching'
  },
  {
    index: 2,
    idName: 'sharing',
    isChecked: false,
    labelText: 'Easy Sharing'
  }
]