import KeyVisualIllus from "../images/dev_productivity.svg"

const KeyVisual = () => (
  <section className="kv">
    <div className="kv__container container">
      <div className="kv__illustration" style={{backgroundImage: `url(${KeyVisualIllus})`}}></div>
      <div className="kv__intro">
        <h1 className="kv__title heading--xxl">A Bookmark Manager for your Tech Interview preparation</h1>
        <p className="kv__text paragraph--l">Any interview preparation is incomplete without solving problems and
          reading knowledge articles
          from multiple sources. Here you can organize your favourite problems, knowledge articles, link etc. all in one
          place, to
          keep important problems and links handy.</p>
      </div>
    </div>
  </section>
);

export default KeyVisual;