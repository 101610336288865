import React, {useState} from 'react';
//MaterialUI
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {CircularProgress, createTheme} from "@mui/material";
import {ThemeProvider} from "@mui/material/styles";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {useNavigate} from "react-router-dom"

import axios from 'axios';

let theme = createTheme();
theme = createTheme(theme, {
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  customError: {
    color: 'red',
    fontSize: '1rem',
    marginTop: 1
  },
  progress: {
    position: 'absolute'
  }
});
export default function Login(props) {
  const navigate = useNavigate();
  const [appState, setAppState] = useState({
    email: '',
    password: '',
    errors: [],
    loading: false
  });

  const handleChange = (e) => {
    setAppState({
      ...appState,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setAppState({
      ...appState,
      loading: true
    });
    const userData = {
      email: appState.email,
      password: appState.password
    };
    axios
      .post('/login', userData)
      .then((response) => {
        localStorage.setItem('AuthToken', `Bearer ${response.data.token}`);
        setAppState({
          ...appState,
          loading: false
        });
        navigate("/home");
      })
      .catch((error) => {
        setAppState({
          ...appState,
          errors: error.response != null ? error.response.data : {"general": "Unexpected error occurred. Sorry for the inconvenience."},
          loading: false
        });
      });
  };
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline/>
        <div style={theme.paper}>
          <Avatar sx={theme.avatar}>
            <LockOutlinedIcon/>
          </Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <form style={theme.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              helperText={appState.errors.email}
              error={appState.errors.email ? true : false}
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              helperText={appState.errors.password}
              error={appState.errors.password ? true : false}
              onChange={handleChange}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={theme.submit}
              onClick={handleSubmit}
              disabled={appState.loading || !appState.email || !appState.password}
            >
              Sign In
              {appState.loading && <CircularProgress size={30} sx={theme.progress}/>}
            </Button>
            <Grid container>
              <Grid item>
                <Link href="signup" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
            {appState.errors.general && (
              <Typography variant="body2" sx={theme.customError}>
                {appState.errors.general}
              </Typography>
            )}
          </form>
        </div>
      </Container>
    </ThemeProvider>
  );
}