import React, {useEffect, useState} from 'react'
import {
  Autocomplete,
  Checkbox,
  Chip,
  createTheme,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  SelectChangeEvent
} from "@mui/material";
import {ThemeProvider} from "@mui/material/styles";
import CircularProgress from '@mui/material/CircularProgress';

import axios from 'axios';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {authMiddleWare, isTokenExpired} from '../util/auth';
import {useNavigate} from "react-router-dom";
import 'react-quill/dist/quill.snow.css';
import List from "@mui/material/List";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Link from "@mui/material/Link";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";


let theme = createTheme();
theme = createTheme(theme, {
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  name: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  toolbar: theme.mixins.toolbar,
  root: {
    minWidth: 470
  },
  pos: {
    marginBottom: 12
  },
  uiProgress: {
    position: 'fixed',
    zIndex: '1000',
    height: '31px',
    width: '31px',
    left: '50%',
    top: '35%'
  },
  difficultyChip: {
    borderRadius: '0.5rem',
  },
  bookmarkList: {
    border: 1,
    // margin: '3rem',
    padding: '2rem',
  },
  tagsChip: {
    marginX: '.25rem'
  },
  paginationButton: {
    left: '40%'
  }
});

export default function Bookmarks(props) {
  const navigate = useNavigate();
  const [appState, setAppState] = useState({
    bookmarks: '',
    uiLoading: true,
    tagsFilter: [],
    difficultyFilter: undefined,
    hideSolvedFilter: false,
    favoriteFilter: false,
    startFromUpdatedAt: undefined,
    disableFetchButton: false
  });
  useEffect(() => {
    authMiddleWare(navigate);
    setAppState({
      ...appState,
      uiLoading: true
    });
    const authToken = localStorage.getItem('AuthToken');
    axios.defaults.headers.common = {Authorization: `${authToken}`};
    axios
      .get('/bookmarks',
        {params: {tags: appState.tagsFilter.join(",")}}
      )
      .then((response) => {
        let newlyFetchedBookmarks = response.data;
        setAppState({
          ...appState,
          bookmarks: newlyFetchedBookmarks,
          uiLoading: false,
          startFromUpdatedAt: newlyFetchedBookmarks.length > 0 ? newlyFetchedBookmarks.slice(-1)[0].updatedAt : undefined,
          disableFetchButton: newlyFetchedBookmarks.length < 10
        });
      })
      .catch((err) => {
        console.log(err);
        if (isTokenExpired(err)) {
          navigate('/login');
        }
        setAppState({...appState, uiLoading: false});
      });
  }, [
    appState.tagsFilter
  ]);
  dayjs.extend(relativeTime);

  const handleTagsFilter = (event, value) => {
    setAppState({
      ...appState,
      tagsFilter: value
    });
  };

  const handleChipClick = (e) => {
    appState.tagsFilter.push(e.currentTarget.id)
    handleTagsFilter(e, appState.tagsFilter);
    e.preventDefault();
  }

  const difficultyTagMapping = (difficulty) => {
    switch (difficulty) {
      case 0:
        return <Chip id={0} label='Easy' color="success" sx={theme.difficultyChip}/>
      case 1:
        return <Chip id={1} label='Medium' color="info" sx={theme.difficultyChip}/>
      case 2:
        return <Chip id={2} label='Hard' color="warning" sx={theme.difficultyChip}/>
      // case 3:
      //   return <Chip label='Very Hard' color="error" sx={theme.difficultyChip} onClick={handleChipClick}/>
      default:
        return ''
    }
  }

  const handleDifficultySelect = (event: SelectChangeEvent) => {
    setAppState({
      ...appState,
      difficultyFilter: event.target.value
    });
  };


  const handleFavoriteSelect = (event) => {
    setAppState({
      ...appState,
      favoriteFilter: event.target.checked
    });
  }

  const handleSolvedSelect = (event) => {
    setAppState({
      ...appState,
      hideSolvedFilter: event.target.checked
    });
  }

  const bookmarksFilter = (bookmark) => {
    if (appState.difficultyFilter !== undefined && appState.difficultyFilter !== bookmark.difficulty) {
      return false;
    }
    if (appState.favoriteFilter !== false && appState.favoriteFilter !== bookmark.favorite) {
      return false;
    }
    if (appState.hideSolvedFilter !== false && appState.hideSolvedFilter === bookmark.solved) {
      return false;
    }
    if (appState.tagsFilter.length !== 0) {
      const tagSet = new Set(bookmark.tags);
      for (let i = 0; i < appState.tagsFilter.length; i++) {
        let tag = appState.tagsFilter[i];
        if (!tagSet.has(tag)) {
          return false;
        }
      }
    }
    return true;
  }

  const fetchOlder = (event) => {
    authMiddleWare(navigate);
    event.preventDefault();
    const authToken = localStorage.getItem('AuthToken');
    axios.defaults.headers.common = {Authorization: `${authToken}`};
    axios
      .get('/bookmarks',
        {
          params: {
            startFromUpdatedAt: appState.startFromUpdatedAt,
            tags: appState.tagsFilter.join(",")
          }
        }
      )
      .then((response) => {
        let currBookmarks = appState.bookmarks;
        let newlyFetchedBookmarks = response.data;
        setAppState({
          ...appState,
          bookmarks: currBookmarks.concat(newlyFetchedBookmarks),
          uiLoading: false,
          startFromUpdatedAt: newlyFetchedBookmarks.length > 0 ? newlyFetchedBookmarks.slice(-1)[0].updatedAt : undefined,
          disableFetchButton: newlyFetchedBookmarks.length < 10
        });
      })
      .catch((err) => {
        console.log(err);
        if (isTokenExpired(err)) {
          navigate('/login');
        }
      });
  };


  if (appState.uiLoading === true) {
    return (
      <ThemeProvider theme={theme}>
        <main style={theme.content}>
          <div style={theme.toolbar}/>
          {appState.uiLoading && <CircularProgress size={150} sx={theme.uiProgress}/>}
        </main>
      </ThemeProvider>

    );
  } else {
    return (
      <ThemeProvider theme={theme}>
        <main style={theme.content}>
          <div style={theme.toolbar}/>
          <Grid container spacing={2} sx={{maxWidth: '60rem'}}>
            <Grid item md={6} xs={12}>
              <Autocomplete
                // size="small"
                multiple
                id="tags-filled"
                options={[]}
                value={appState.tagsFilter}
                freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({index})} />
                  ))
                }
                onChange={handleTagsFilter}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Filter by Tags"
                    placeholder="Add"
                  />
                )}
                sx={theme.tagsFilter}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <FormControl sx={{m: 1, minWidth: 120}} size="small">
                <InputLabel>Difficulty</InputLabel>
                <Select
                  id="difficulty-filter"
                  value={appState.difficulty}
                  label="Difficulty"
                  onChange={handleDifficultySelect}
                >
                  <MenuItem value={undefined}>All</MenuItem>
                  <MenuItem value={0}>Easy</MenuItem>
                  <MenuItem value={1}>Medium</MenuItem>
                  <MenuItem value={2}>Hard</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2} xs={12}>
              <FormControlLabel
                label="Favorites only"
                control={<Checkbox checked={appState.favoriteFilter} disabled={appState.buttonType === ''}
                                   onChange={handleFavoriteSelect} icon={<StarBorderIcon/>}
                                   checkedIcon={<StarIcon/>}/>}
                sx={{marginLeft: '1rem'}}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <FormControlLabel
                label="Hide Solved"
                control={<Checkbox checked={appState.hideSolvedFilter} disabled={appState.buttonType === ''}
                                   onChange={handleSolvedSelect}/>}
                sx={{marginLeft: '1rem'}}
              />
            </Grid>
            <Grid item xs={12}>
              <List sx={theme.bookmarkList}>
                {appState.bookmarks.filter(bookmarksFilter).map((bookmark) => (
                  <ListItem
                    secondaryAction={
                      <React.Fragment>
                        {difficultyTagMapping(bookmark.difficulty)}
                        <Checkbox disabled checked={bookmark.favorite} icon={<StarBorderIcon/>}
                                  checkedIcon={<StarIcon/>}
                                  sx={{marginX: '.5rem'}}/>
                        <Checkbox disabled checked={bookmark.solved} sx={{marginX: '.5rem'}}/>
                      </React.Fragment>
                    }>
                    <Link
                      sx={{textDecoration: 'none'}}
                      href={'/bookmark/' + bookmark.bookmarkId}
                    >
                      <ListItemText id={bookmark.name} primary={bookmark.name} sx={{marginRight: '10rem'}}
                                    secondary={bookmark.tags.slice(0, 4).map((tag) => (
                                      <Chip label={tag} id={tag} variant="outlined" onClick={handleChipClick}
                                            sx={theme.tagsChip}/>
                                    ))}
                      />
                    </Link>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={fetchOlder}
                disabled={appState.disableFetchButton}
                sx={theme.paginationButton}
              >
                Fetch Older
              </Button>
            </Grid>
          </Grid>
        </main>
      </ThemeProvider>
    );
  }
}
