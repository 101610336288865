function authMiddleWare(navigate) {
  const authToken = localStorage.getItem('AuthToken');
  if (authToken === null) {
    navigate('/login')
  }
}

function isTokenExpired(error) {
  return error.response != null && error.response.status === 403 && error.response.data != null && error.response.data.code === 'auth/id-token-expired'
}

export {
  authMiddleWare, isTokenExpired
}