import React, {useEffect, useState} from 'react';
import axios from 'axios';

import Account from '../components/account';
import Bookmarks from './bookmarks';

import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import NotesIcon from '@mui/icons-material/Notes';
import Avatar from '@mui/material/Avatar';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CircularProgress from '@mui/material/CircularProgress';
import {createTheme} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {ThemeProvider} from "@mui/material/styles";
import {authMiddleWare, isTokenExpired} from "../util/auth";
import Button from "@mui/material/Button";

const drawerWidth = 260;

let theme = createTheme();
theme = createTheme(theme, {
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: drawerWidth,
      boxSizing: 'border-box',
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  avatar: {
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
    marginTop: 20
  },
  uiProgress: {
    position: 'fixed',
    zIndex: '1000',
    height: '31px',
    width: '31px',
    left: '45%',
    top: '35%'
  },
  toolbar: theme.mixins.toolbar,
  floatingButton: {
    position: 'fixed',
    bottom: theme.spacing(20),
    right: theme.spacing(20)
  },
  createButton: {
    display: 'block',
    color: 'white',
    textAlign: 'center',
    position: 'absolute',
    top: 14,
    right: 10
  },
});

export default function Home(props) {
  const navigate = useNavigate();
  const [appState, setAppState] = useState({
    firstName: '',
    lastName: '',
    profilePicture: '',
    uiLoading: true,
    imageLoading: false,
    render: false,
  });

  const loadAccountPage = (event) => {
    setAppState({...appState, render: true});
  };

  const loadBookmarksPage = (event) => {
    setAppState({...appState, render: false});
  };

  const logoutHandler = (event) => {
    localStorage.removeItem('AuthToken');
    navigate('/login');
  };

  useEffect(() => {
    authMiddleWare(navigate);
    const authToken = localStorage.getItem('AuthToken');
    axios.defaults.headers.common = {Authorization: `${authToken}`};
    axios
      .get('/user')
      .then((response) => {
        // console.log(response.data);
        setAppState({
          ...appState,
          firstName: response.data.userCredentials.firstName,
          lastName: response.data.userCredentials.lastName,
          email: response.data.userCredentials.email,
          phoneNumber: response.data.userCredentials.phoneNumber,
          country: response.data.userCredentials.country,
          username: response.data.userCredentials.username,
          uiLoading: false,
          profilePicture: response.data.userCredentials.imageUrl
        });
      })
      .catch((error) => {
        // console.log(error);
        if (isTokenExpired(error)) {
          navigate('/login');
        }
        setAppState({...appState, errorMsg: 'Error in retrieving the data'});
      });
  }, []);

  if (appState.uiLoading === true) {
    return (
      <ThemeProvider theme={theme}>
        <div style={theme.root}>
          {appState.uiLoading && <CircularProgress size={150} sx={theme.uiProgress}/>}
        </div>
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={theme}>

        <div style={theme.root}>
          <CssBaseline/>
          <AppBar position="fixed" sx={theme.appBar}>
            <Toolbar>
              <Typography variant="h6" noWrap>
                CodeBookmark
              </Typography>
              <Button
                autoFocus
                color="inherit"
                href="/bookmark/new"
                sx={theme.createButton}
              >
                Create a new Bookmark
              </Button>
            </Toolbar>
          </AppBar>
          <Drawer
            sx={theme.drawer}
            variant="permanent"
            anchor="left"
          >
            <div style={theme.toolbar}/>
            <Divider/>
            <center>
              <Avatar src={appState.profilePicture} sx={theme.avatar}/>
              <p>
                {' '}
                {appState.firstName} {appState.lastName}
              </p>
            </center>
            <Divider/>
            <List>
              <ListItem button key="Bookmarks" onClick={loadBookmarksPage}>
                <ListItemIcon>
                  {' '}
                  <NotesIcon/>{' '}
                </ListItemIcon>
                <ListItemText primary="Bookmarks"/>
              </ListItem>

              <ListItem button key="Account" onClick={loadAccountPage}>
                <ListItemIcon>
                  {' '}
                  <AccountBoxIcon/>{' '}
                </ListItemIcon>
                <ListItemText primary="Account"/>
              </ListItem>

              <ListItem button key="Logout" onClick={logoutHandler}>
                <ListItemIcon>
                  {' '}
                  <ExitToAppIcon/>{' '}
                </ListItemIcon>
                <ListItemText primary="Logout"/>
              </ListItem>
            </List>
          </Drawer>

          <div>{appState.render ? <Account/> : <Bookmarks/>}</div>
        </div>
      </ThemeProvider>

    );
  }
}
