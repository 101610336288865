import React, {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {Button, Card, CardActions, CardContent, createTheme, Divider, Grid, TextField} from '@mui/material';

import clsx from 'clsx';

import axios from 'axios';
import {authMiddleWare, isTokenExpired} from '../util/auth';
import {useNavigate} from "react-router-dom";
import {ThemeProvider} from "@mui/material/styles";


let theme = createTheme();
theme = createTheme(theme, {
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  toolbar: theme.mixins.toolbar,
  root: {},
  details: {
    display: 'flex'
  },
  avatar: {
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0
  },
  locationText: {
    paddingLeft: '15px'
  },
  buttonProperty: {
    position: 'absolute',
    top: '50%'
  },
  uiProgress: {
    position: 'fixed',
    zIndex: '1000',
    height: '31px',
    width: '31px',
    left: '50%',
    top: '35%'
  },
  progress: {
    position: 'absolute'
  },
  uploadButton: {
    marginLeft: '8px',
    margin: theme.spacing(1)
  },
  customError: {
    color: 'red',
    fontSize: '0.8rem',
    marginTop: 10
  },
  submitButton: {
    marginTop: '10px'
  }
});

export default function Account(props) {
  const navigate = useNavigate();
  const [appState, setAppState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    username: '',
    country: '',
    profilePicture: '',
    uiLoading: true,
    buttonLoading: false,
    imageError: ''
  });

  useEffect(() => {
    authMiddleWare(navigate);
    const authToken = localStorage.getItem('AuthToken');
    axios.defaults.headers.common = {Authorization: `${authToken}`};
    axios
      .get('/user')
      .then((response) => {
        setAppState({
          ...appState,
          firstName: response.data.userCredentials.firstName,
          lastName: response.data.userCredentials.lastName,
          email: response.data.userCredentials.email,
          phoneNumber: response.data.userCredentials.phoneNumber,
          country: response.data.userCredentials.country,
          username: response.data.userCredentials.username,
          uiLoading: false
        });
      })
      .catch((error) => {
        if (isTokenExpired(error)) {
          navigate('/login');
        }
        setAppState({...appState, errorMsg: 'Error in retrieving the data'});
      });
  }, []);

  const handleChange = (e) => {
    setAppState({
      ...appState,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageChange = (e) => {
    setAppState({
      ...appState,
      image: e.target.files[0]
    });
  };

  const profilePictureHandler = (event) => {
    event.preventDefault();
    setAppState({
      ...appState,
      uiLoading: true
    });
    authMiddleWare(navigate);
    const authToken = localStorage.getItem('AuthToken');
    let form_data = new FormData();
    form_data.append('image', appState.image);
    form_data.append('content', appState.content);
    // axios.defaults.headers.common = {Authorization: `${authToken}`};
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `${authToken}`
      }
    };
    axios
      .post('/user/image', form_data, config)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        if (isTokenExpired(error)) {
          navigate('/login');
        }
        setAppState({
          ...appState,
          uiLoading: false,
          imageError: 'Error in posting the data'
        });
      });
  };

  const updateFormValues = (event) => {
    event.preventDefault();
    setAppState({...appState, buttonLoading: true});
    authMiddleWare(navigate);
    const authToken = localStorage.getItem('AuthToken');
    axios.defaults.headers.common = {Authorization: `${authToken}`};
    const formRequest = {
      firstName: appState.firstName,
      lastName: appState.lastName,
      country: appState.country,
      phoneNumber: appState.phoneNumber
    };
    axios
      .post('/user', formRequest)
      .then(() => {
        setAppState({...appState, buttonLoading: false});
      })
      .catch((error) => {
        console.log(error);
        if (isTokenExpired(error)) {
          navigate('/login');
        }
        setAppState({
          ...appState,
          buttonLoading: false
        });
      });
  };


  if (appState.uiLoading === true) {
    return (
      <ThemeProvider theme={theme}>
        <main style={theme.content}>
          <div style={theme.toolbar}/>
          {appState.uiLoading && <CircularProgress size={150} sx={theme.uiProgress}/>}
        </main>
      </ThemeProvider>

    );
  } else {
    const {...rest} = props;
    return (
      <ThemeProvider theme={theme}>
        <main style={theme.content}>
          <div style={theme.toolbar}/>
          <Card {...rest} className={clsx(theme.root, theme)}>
            <CardContent>
              <div style={theme.details}>
                <div>
                  <Typography sx={theme.locationText} gutterBottom variant="h4">
                    {appState.firstName} {appState.lastName}
                  </Typography>
                  <Button
                    variant="outlined"
                    color="primary"
                    type="submit"
                    size="small"
                    startIcon={<CloudUploadIcon/>}
                    sx={theme.uploadButton}
                    onClick={profilePictureHandler}
                    disabled={true}
                  >
                    Upload Photo
                  </Button>
                  <input type="file" onChange={handleImageChange}/>

                  {appState.imageError ? (
                    <div style={theme.customError}>
                      {' '}
                      Wrong Image Format || Supported Format are PNG and JPG
                    </div>
                  ) : false}
                </div>
              </div>
              <div style={theme.progress}/>
            </CardContent>
            <Divider/>
          </Card>

          <br/>
          <Card {...rest} className={clsx(theme.root, theme)}>
            <form autoComplete="off" noValidate>
              <Divider/>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="First name"
                      margin="dense"
                      name="firstName"
                      variant="outlined"
                      value={appState.firstName}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Last name"
                      margin="dense"
                      name="lastName"
                      variant="outlined"
                      value={appState.lastName}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Email"
                      margin="dense"
                      name="email"
                      variant="outlined"
                      disabled={true}
                      value={appState.email}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Phone Number"
                      margin="dense"
                      name="phoneNumber"
                      type="number"
                      variant="outlined"
                      value={appState.phoneNumber}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="User Name"
                      margin="dense"
                      name="userHandle"
                      disabled={true}
                      variant="outlined"
                      value={appState.username}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Country"
                      margin="dense"
                      name="country"
                      variant="outlined"
                      value={appState.country}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider/>
              <CardActions/>
            </form>
          </Card>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            sx={theme.submitButton}
            onClick={updateFormValues}
            disabled={
              appState.buttonLoading ||
              !appState.firstName ||
              !appState.lastName ||
              !appState.country
            }
          >
            Save details
            {appState.buttonLoading && <CircularProgress size={30} sx={theme.progress}/>}
          </Button>
        </main>
      </ThemeProvider>
    );
  }
}