import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from "./components/home";
import Login from "./components/login";
import Signup from "./components/signup";
import React from "react";
import SingleBookmark from "./components/singleBookmark";
import Intro from "./intro/intro";

export default function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Intro/>}/>
          <Route path="/home" element={<Home/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/signup" element={<Signup/>}/>
          <Route path="/bookmark/:bookmarkId" element={<SingleBookmark/>}/>
        </Routes>
      </div>
    </Router>
  );
}