// https://codesandbox.io/s/react-quill-full-toolbar-j569z?file=/src/EditorToolbar.js

import ReactQuill from 'react-quill'

let Quill = ReactQuill.Quill;

let Block = Quill.import('blots/block');
let Inline = Quill.import('blots/inline');
let Container = Quill.import('blots/container')
let TextBlot = Quill.import('blots/text')
let Break = Quill.import('blots/break');
let Cursor = Quill.import('blots/cursor')

function escapeText(text: string) {
  return text.replace(/[&<>"']/g, (s) => {
    // https://lodash.com/docs#escape
    const entityMap: Record<string, string> = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#39;',
    };
    return entityMap[s];
  });
}

class DetailsBlockContainer extends Container {
  static create(value: string) {
    const domNode = super.create(value);
    return domNode;
  }

  code(index: number, length: number) {
    return (
      this.children
        .map((child) => (child.length() <= 1 ? '' : child.domNode.innerText))
        .join('\n')
        .slice(index, index + length)
    );
  }

  html(index: number, length: number) {
    // `\n`s are needed in order to support empty lines at the beginning and the end.
    // https://html.spec.whatwg.org/multipage/syntax.html#element-restrictions
    return `<details>\n${escapeText(this.code(index, length))}\n</details>`;
  }
}

class DetailsBlock extends Block {
  static TAB = '  ';

  static register() {
    Quill.register(DetailsBlockContainer);
  }
}

class DetailsBlot extends Inline {
}

DetailsBlot.blotName = 'details';
DetailsBlot.tagName = 'details';

DetailsBlock.blotName = 'details-block';
DetailsBlock.className = 'ql-details-block';
DetailsBlock.tagName = 'DIV';
DetailsBlockContainer.blotName = 'details-block-container';
DetailsBlockContainer.className = 'ql-details-block-container';
DetailsBlockContainer.tagName = 'DIV';

DetailsBlockContainer.allowedChildren = [DetailsBlock];

DetailsBlock.allowedChildren = [TextBlot, Break, Cursor];
DetailsBlock.requiredContainer = DetailsBlockContainer;


Quill.register('formats/details', DetailsBlot);
Quill.register('formats/details-block', DetailsBlock);

const DetailsButton = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-square"
       viewBox="0 0 16 16">
    <path d="M3.626 6.832A.5.5 0 0 1 4 6h8a.5.5 0 0 1 .374.832l-4 4.5a.5.5 0 0 1-.748 0l-4-4.5z"/>
    <path
      d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2z"/>
  </svg>
);

function insertDetails() {
  this.quill.format('details', true);
  // const cursorPosition = this.quill.getSelection().index;
  // this.quill.insertText(cursorPosition, "★");
  // this.quill.setSelection(cursorPosition + 1);
}

export const toolbar = {
  container: "#toolbar",
  handlers: {
    insertDetails: insertDetails
  }
};

export const QuillToolbar = () => (
  <div id="toolbar">
    <span className="ql-formats">
      <select className="ql-header" defaultValue="3">
        <option value="1">Heading</option>
        <option value="2">Subheading</option>
        <option value="3">Normal</option>
      </select>
    </span>
    <span className="ql-formats">
      <button className="ql-bold"/>
      <button className="ql-italic"/>
      <button className="ql-underline"/>
      <button className="ql-strike"/>
    </span>
    <span className="ql-formats">
      <button className="ql-list" value="ordered"/>
      <button className="ql-list" value="bullet"/>
      <button className="ql-indent" value="-1"/>
      <button className="ql-indent" value="+1"/>
    </span>
    <span className="ql-formats">
      <select className="ql-align"/>
      <select className="ql-color"/>
      <select className="ql-background"/>
    </span>
    <span className="ql-formats">
      <button className="ql-link"/>
      <button className="ql-code-block"/>
      <button className="ql-clean"/>
      <button className="ql-insertDetails">
        <DetailsButton/>
      </button>
    </span>
  </div>
);