import React, {useEffect, useState} from 'react'
import './styles.singleBookmark.css'

import Typography from '@mui/material/Typography';
import {
  Autocomplete,
  Checkbox,
  Chip,
  createTheme,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from "@mui/material";
import {ThemeProvider} from "@mui/material/styles";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import MuiDialogTitle from '@mui/material/DialogTitle';

import axios from 'axios';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {authMiddleWare, isTokenExpired} from '../util/auth';
import {useNavigate, useParams} from "react-router-dom";
import {TransitionProps} from "@mui/material/transitions";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";

import './highlight.js'
import ReactQuill from 'react-quill'
import {QuillToolbar, toolbar} from "./QuillCustomization";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


let theme = createTheme();
theme = createTheme(theme, {
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  appBar: {
    position: 'fixed'
  },
  name: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  submitButton: {
    display: 'block',
    color: 'white',
    textAlign: 'center'
  },
  floatingButton: {
    position: 'fixed',
    bottom: 0,
    right: 0
  },
  form: {
    width: '98%',
    marginLeft: 13,
    marginTop: theme.spacing(13)
  },
  toolbar: theme.mixins.toolbar,
  root: {
    minWidth: 470
  },
  uiProgress: {
    position: 'fixed',
    zIndex: '1000',
    height: '31px',
    width: '31px',
    left: '50%',
    top: '35%'
  },
  viewRoot: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  difficultyChip: {
    borderRadius: '0.5rem',
    marginLeft: theme.spacing(2),
  },
});

export default function SingleBookmark(props) {
  const navigate = useNavigate();
  // useParams allows use to extract parameters from the url, in this case "bookmarkId"
  const {bookmarkId} = useParams();
  const [appState, setAppState] = useState({
    name: '',
    content: '',
    bookmarkId: '',
    errors: [],
    open: false,
    uiLoading: true,
    buttonType: '',
    tags: [],
    difficulty: -1,
    favorite: false,
    solved: false,
    isPublic: false,
    deleteWarningOpen: false,
    sessionWarningOpen: false,
    errorWarningOpen: false
  });

  const handleChange = (e) => {
    setAppState({
      ...appState,
      [e.target.name]: e.target.value,
    });
  };

  const getQuillData = (value) => {
    setAppState({
      ...appState,
      content: value
    })
  }
  useEffect(() => {
    authMiddleWare(navigate);
    if (bookmarkId === 'new') {
      setAppState({
        ...appState,
        open: true,
        buttonType: 'Create',
        uiLoading: false
      });
    } else {
      const authToken = localStorage.getItem('AuthToken');
      axios.defaults.headers.common = {Authorization: `${authToken}`};
      axios
        .get(`bookmark/${bookmarkId}`)
        .then((response) => {
          setAppState({
            ...appState,
            name: response.data.name,
            content: response.data.content,
            bookmarkId: response.data.bookmarkId,
            tags: response.data.tags,
            difficulty: response.data.difficulty,
            favorite: response.data.favorite,
            solved: response.data.solved,
            isPublic: response.data.isPublic,
            buttonType: '',
            open: true,
            uiLoading: false
          });
        })
        .catch((error) => {
          // console.log(error);
          if (isTokenExpired(error)) {
            navigate('/login');
          }
          setAppState({
            ...appState, open: true, errors: error.response.data, uiLoading: false, errorWarningOpen: true
          });
        });
    }
  }, []);

  function deleteBookmarkHandler() {
    setAppState({
      ...appState,
      deleteWarningOpen: true
    });
  }

  function handleEditClickOpen() {
    authMiddleWare(navigate);
    setAppState({
      ...appState,
      buttonType: 'Edit',
    });
  }

  const DialogTitle = (props) => {
    const {children, onClose, ...other} = props;
    return (
      <ThemeProvider theme={theme}>
        <MuiDialogTitle disableTypography sx={theme.root} {...other}>
          <Typography variant="h6">{children}</Typography>
          {onClose ? (
            <IconButton aria-label="close" sx={theme.closeButton} onClick={onClose}>
              <CloseIcon/>
            </IconButton>
          ) : null}
        </MuiDialogTitle>
      </ThemeProvider>
    );
  }

  dayjs.extend(relativeTime);

  const handleSubmit = (event) => {
    authMiddleWare(navigate);
    setAppState({
      ...appState,
      open: false,
      uiLoading: true
    });
    event.preventDefault();
    const userBookmark = {
      name: appState.name,
      content: appState.content,
      tags: appState.tags,
      difficulty: appState.difficulty,
      favorite: appState.favorite,
      solved: appState.solved,
      isPublic: appState.isPublic,
    };
    let options = {};
    if (appState.buttonType === 'Edit') {
      options = {
        url: `/bookmark/${appState.bookmarkId}`,
        method: 'put',
        data: userBookmark
      };
    } else {
      options = {
        url: '/bookmark',
        method: 'post',
        data: userBookmark
      };
    }
    const authToken = localStorage.getItem('AuthToken');
    axios.defaults.headers.common = {Authorization: `${authToken}`};
    axios(options)
      .then((response) => {
        if (appState.buttonType === 'Create') {
          window.history.replaceState(null, null, "/bookmark/" + response.data.bookmarkId);
        }
        setAppState({
          ...appState,
          name: response.data.name,
          content: response.data.content,
          bookmarkId: response.data.bookmarkId,
          tags: response.data.tags,
          difficulty: response.data.difficulty,
          favorite: response.data.favorite,
          solved: response.data.solved,
          isPublic: response.data.isPublic,
          uiLoading: false,
          buttonType: ''
        });
      })
      .catch((error) => {
        if (isTokenExpired(error)) {
          console.log("Session expired");
          setAppState({
            ...appState, open: true, errors: error.response.data, uiLoading: false, sessionWarningOpen: true
          });
        } else {
          // console.log(error);
          setAppState({
            ...appState, open: true, errors: error.response.data, uiLoading: false, errorWarningOpen: true
          });
        }
      });
  };

  const handleClose = (event) => {
    setAppState({
      ...appState, uiLoading: true, open: false
    });
    navigate("/home");
  };

  const handleTagsChange = (event, value) => {
    setAppState({
      ...appState,
      tags: value
    });
  };

  const handleDeleteWarningClose = (event) => {
    if (event.target.name === "confirm") {
      setAppState({
        ...appState,
        uiLoading: true
      });
      authMiddleWare(navigate);
      const authToken = localStorage.getItem('AuthToken');
      axios.defaults.headers.common = {Authorization: `${authToken}`};
      let bookmarkId = appState.bookmarkId;
      axios
        .delete(`bookmark/${bookmarkId}`)
        .then(() => {
          navigate("/home");
        })
        .catch((err) => {
          console.log(err);
          if (isTokenExpired(err)) {
            navigate('/login');
          }
        });
    }
    setAppState({
      ...appState,
      deleteWarningOpen: false
    });
  };

  const handleSessionWarningClose = (event) => {
    setAppState({
      ...appState,
      sessionWarningOpen: false
    });
  };

  const handleErrorWarningClose = (event) => {
    setAppState({
      ...appState,
      errorWarningOpen: false
    });
  }


  const pageTitleSwitch = (buttonType) => {
    switch (buttonType) {
      case 'Edit':
        return 'Editing Bookmark'
      case 'Create':
        return 'Creating a new Bookmark'
      default:
        return 'Viewing Bookmark'
    }
  }

  const pageToolbarSwitch = (buttonType) => {
    switch (buttonType) {
      case 'Edit':
        return <React.Fragment>
          <Button
            autoFocus
            color="inherit"
            onClick={handleSubmit}
            sx={theme.submitButton}
          >
            Save
          </Button>
        </React.Fragment>
      case 'Create':
        return <Button
          autoFocus
          color="inherit"
          onClick={handleSubmit}
          sx={theme.submitButton}
        >
          Create
        </Button>
      default:
        return <React.Fragment>
          <Button
            color="inherit"
            onClick={handleEditClickOpen}
            sx={theme.submitButton}
          >
            Edit
          </Button>
          <Button
            color="inherit"
            onClick={deleteBookmarkHandler}
            sx={theme.submitButton}
          >
            Delete
          </Button>
        </React.Fragment>
    }
  }


  const difficultyTagMapping = (difficulty) => {
    switch (difficulty) {
      case 0:
        return <Chip label='Easy' color="success" sx={theme.difficultyChip}/>
      case 1:
        return <Chip label='Medium' color="info" sx={theme.difficultyChip}/>
      case 2:
        return <Chip label='Hard' color="warning" sx={theme.difficultyChip}/>
      // case 3:
      //   return <Chip label='Very Hard' color="error" sx={theme.difficultyChip}/>
      default:
        return ''
    }
  }

  const handleDifficultySelect = (event: SelectChangeEvent) => {
    setAppState({
      ...appState,
      difficulty: event.target.value
    });
  };

  const handleFavoriteChange = (event) => {
    setAppState({
      ...appState,
      favorite: event.target.checked
    });
  }

  const handleSolvedChange = (event) => {
    setAppState({
      ...appState,
      solved: event.target.checked
    });
  }

  const {open, errors, sessionWarningOpen, deleteWarningOpen, errorWarningOpen} = appState;

  if (appState.uiLoading === true) {
    return (
      <ThemeProvider theme={theme}>
        <main style={theme.content}>
          <div style={theme.toolbar}/>
          {appState.uiLoading && <CircularProgress size={150} sx={theme.uiProgress}/>}
        </main>
      </ThemeProvider>

    );
  } else {
    return (
      <ThemeProvider theme={theme}>
        <main style={theme.content}>
          <div style={theme.toolbar}/>
          <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar sx={theme.appBar}>
              <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                  <CloseIcon/>
                </IconButton>
                <Typography variant="h6" sx={theme.name}>
                  {pageTitleSwitch(appState.buttonType)}
                </Typography>
                {pageToolbarSwitch(appState.buttonType)}
              </Toolbar>
            </AppBar>

            <form style={theme.form} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {appState.buttonType === '' ? <DialogTitle>{appState.name}</DialogTitle> :
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="bookmarkName"
                      label="Name"
                      name="name"
                      autoComplete="bookmarkName"
                      helperText={errors.name}
                      value={appState.name}
                      error={errors.name ? true : false}
                      onChange={handleChange}
                    />
                  }
                </Grid>
                <Grid item xs={12}>
                  {appState.buttonType === '' ? difficultyTagMapping(appState.difficulty) :
                    <FormControl sx={{m: 1, minWidth: 120}} size="small">
                      <InputLabel id="difficulty-select-label">Difficulty</InputLabel>
                      <Select
                        id="difficulty-select"
                        value={appState.difficulty}
                        label="Difficulty"
                        onChange={handleDifficultySelect}
                      >
                        <MenuItem value={-1}><em>None</em></MenuItem>
                        <MenuItem value={0}>Easy</MenuItem>
                        <MenuItem value={1}>Medium</MenuItem>
                        <MenuItem value={2}>Hard</MenuItem>
                        {/*<MenuItem value={3}>Very Hard</MenuItem>*/}
                      </Select>
                    </FormControl>
                  }
                  <Checkbox checked={appState.favorite} disabled={appState.buttonType === ''}
                            onChange={handleFavoriteChange} icon={<StarBorderIcon/>} checkedIcon={<StarIcon/>}/>
                  <Checkbox checked={appState.solved} disabled={appState.buttonType === ''}
                            onChange={handleSolvedChange}/>
                </Grid>
                <Grid item xs={12}>
                  {appState.buttonType === '' ? <Grid container>
                      <Grid item xs={1}>
                        <DialogContent>
                          <DialogContentText>
                            Tags
                          </DialogContentText>
                        </DialogContent>
                      </Grid>
                      <Grid item xs={11}>
                        <DialogContent>
                          {appState.tags.map((tag) => (
                            <Chip label={tag} id={tag} variant="outlined"/>
                          ))}
                        </DialogContent>
                      </Grid>
                    </Grid>
                    : <Autocomplete
                      multiple
                      id="tags-filled"
                      options={[]}
                      value={appState.tags}
                      freeSolo
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip variant="outlined" label={option} {...getTagProps({index})} />
                        ))
                      }
                      onChange={handleTagsChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          label="Tags"
                          placeholder="Add a new tag"
                        />
                      )}
                    />
                  }
                </Grid>
                {appState.buttonType === '' ? <Grid item xs={12} marginTop={3}>
                  <ReactQuill
                    onChange={getQuillData}
                    modules={{
                      syntax: true,
                      clipboard: {
                        matchVisual: false
                      }
                    }}
                    value={appState.content}
                    readOnly={true}
                    theme={'bubble'}
                  />
                </Grid> : null}
                {appState.buttonType !== '' ? <Grid item xs={12} marginTop={3}>
                  <QuillToolbar/>
                  <ReactQuill
                    onChange={getQuillData}
                    modules={{
                      syntax: true,
                      toolbar: toolbar,
                      clipboard: {
                        matchVisual: false
                      }
                    }}
                    value={appState.content}
                    readOnly={false}
                    theme={'snow'}
                  />
                </Grid> : null}
              </Grid>
            </form>
          </Dialog>

          <Dialog
            open={deleteWarningOpen}
            onClose={handleDeleteWarningClose}
          >
            <DialogTitle>
              {"Remove the bookmark \"" + appState.name + "\" ?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Please note that the bookmark wouldn't be recoverable once removed.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button name="cancel" onClick={handleDeleteWarningClose} autoFocus>Cancel</Button>
              <Button name="confirm" onClick={handleDeleteWarningClose}>Confirm</Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={sessionWarningOpen}
            onClose={handleSessionWarningClose}
          >
            <DialogTitle>
              {"Session expired"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Please login from another tab, and submit again.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button name="ok" onClick={handleSessionWarningClose} autoFocus>Ok</Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={errorWarningOpen}
            onClose={handleErrorWarningClose}
          >
            <DialogTitle>
              {"Unexpected error"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                An unexpected error occurred. Sorry for the inconvenience.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button name="ok" onClick={handleErrorWarningClose} autoFocus>Ok</Button>
            </DialogActions>
          </Dialog>
        </main>
      </ThemeProvider>
    );
  }
}
